import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Button,
    Checkbox,
    Col,
    Dropdown,
    MenuProps,
    notification,
    Row,
    Space,
    Spin,
    Table,
    TablePaginationConfig,
    TableProps,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FilterValue } from 'antd/es/table/interface';
import { TableRowSelection } from 'antd/lib/table/interface';
import moment from 'moment';
import { QueryOptions } from 'odata-query';
import { PlainObject } from 'odata-query';
import { CaretDownOutlined, PrinterOutlined } from '@ant-design/icons';
import { ReloadOutlined } from '@ant-design/icons';
import { getColumns } from './components/getColumn';
import Modal from '../../components/common/Modal';
import SessionReportModal from '../../components/form/session-forn/SessionReportModal';
import ReportModal from '../../components/modal/ReportModal';
import { DATE_FORMAT_FOR_ORACLE_QUERY } from '../../constants/Alert';
import { RULES_TYPE_CRITERION, RULES_TYPE_INDICATOR } from '../../constants/Rules';
import { BASE_NOTIFICATION_CONFIG } from '../../constants/common';
import { ReportTypes } from '../../constants/enums';
import { filterRules } from '../../helpers/filterRules';
import { makeFilterQuery } from '../../helpers/filterSession';
import getTopSkip from '../../helpers/getTopSkip';
import { useModal } from '../../hooks';
import { Session } from '../../models/Session';
import { useLazyGetSystemDictionaryQuery } from '../../redux/api/dictionaryApi';
import { useLazyGetRolesQuery } from '../../redux/api/rolesApi';
import { useGetRulesQuery, useGetSessionsQuery, useStartSessionMutation } from '../../redux/api/rulesApi';

const printButtons = (
    toggleUntriggeredRisksModal: () => void,
    handleUntriggeredIndicators: () => void,
    openSessionRegisterModal: () => void,
): MenuProps['items'] => [
    {
        key: '1',
        label: <div>Друк "Звіту, по яким критеріям під час відпрацьованої сесії не відбулась генерація алертів"</div>,
        onClick: toggleUntriggeredRisksModal,
    },
    {
        key: '2',
        label: <div>Друк “Звіту, по яким індикаторам під час відпрацьованої сесії не відбулась генерація алертів”</div>,
        onClick: handleUntriggeredIndicators,
    },
    {
        key: '3',
        label: <div>Друк "Звіту щодо відсутності генерації певних алертів у сесії"</div>,
        disabled: true,
    },
    {
        key: '4',
        label: <div>Друк "Реєстру сесій"</div>,
        onClick: openSessionRegisterModal,
    },
];

const defaultOrder = 'id desc';
const defaultFilter = [
    {
        startDate: {
            gt: moment(new Date()).subtract(7, 'd').startOf('day').format(DATE_FORMAT_FOR_ORACLE_QUERY),
        },
    },
];

const SessionRegister = () => {
    const inputRef = useRef(null);
    const [selectedRow, setSelectedRow] = useState<Array<Session>>([]);
    const [getAllSessions, setGetAllSessions] = useState(false);
    const handleCheckboxChange = (e: CheckboxChangeEvent) => setGetAllSessions(e.target.checked);
    const [untriggeredIndicatorsModal, setUntriggeredIndicatorsModal] = useState(false);
    const [untriggeredRisksModal, setUntriggeredRisksModal] = useState(false);
    const toggleUntriggeredIndicatorsModal = () => setUntriggeredIndicatorsModal((prev) => !prev);
    const toggleUntriggeredRisksModal = () => setUntriggeredRisksModal((prev) => !prev);
    const [sessionRegisterModal, openSessionRegisterModall, closeSessionRegisterModal] = useModal();
    const [filter, setFilter] = useState<Record<string, FilterValue | null>>({});
    const [order, setOrder] = useState<QueryOptions<Session>['orderBy']>(defaultOrder);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });
    const sizes = useMemo(() => {
        const tableSizeJson = window.localStorage.getItem('sessionTableSize');
        return tableSizeJson ? JSON.parse(tableSizeJson) : null;
    }, []);
    const queryData = useMemo<Partial<QueryOptions<Session>>>(() => {
        const allFilter = getAllSessions
            ? makeFilterQuery(filter)
            : [...(makeFilterQuery(filter) as Array<string | PlainObject>), ...defaultFilter];
        return {
            count: true,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: allFilter,
            orderBy: order,
        };
    }, [getAllSessions, filter, pagination, order]);
    const { data, error, isLoading, refetch, isFetching } = useGetSessionsQuery(queryData);
    const { data: catalog, error: catalogError, isLoading: isLoadingRules } = useGetRulesQuery({});
    const [startSession, { isLoading: isLoadingRestart }] = useStartSessionMutation();
    const [getRoles, { data: roles }] = useLazyGetRolesQuery();
    const [getStatuses, { data: sessionStatuses }] = useLazyGetSystemDictionaryQuery();
    const rules = useMemo(
        () =>
            catalog
                ? filterRules(catalog, RULES_TYPE_CRITERION).map((item) => ({
                      value: item.code,
                      text: item.code,
                  }))
                : [],
        [catalog],
    );
    const indicators = useMemo(
        () =>
            catalog
                ? filterRules(catalog, RULES_TYPE_INDICATOR).map((item) => ({
                      value: item.code,
                      text: item.code,
                  }))
                : [],
        [catalog],
    );

    const handleTableChange: TableProps<Session>['onChange'] = (newPagination, filters, sorter: any) => {
        setPagination(newPagination);

        if (sorter.order) {
            setOrder(`${sorter?.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`);
        }

        setFilter(filters);
    };

    const disabledRestartButton = useMemo(() => {
        return !(
            (selectedRow.length === 1)
            // NOTE: commented for test
            // && moment(selectedRow[0].startDate).day() === moment(new Date()).day()
        );
    }, [selectedRow]);

    const onSelectChange: TableRowSelection<Session>['onChange'] = (_newSelectedRowKeys, newSelectedRow) => {
        setSelectedRow(newSelectedRow);
    };

    const submitRestartSession = () => {
        if (selectedRow[0].intervals && selectedRow[0].intervals.length > 0)
            startSession(selectedRow[0])
                .unwrap()
                .then(() => notification.success({ ...BASE_NOTIFICATION_CONFIG, message: 'Сесію перезапущено' }))
                .catch((error) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error?.data?.message }));
    };

    const collection = data?.items;
    const count = data?.count;

    const rowSelection = {
        selectedRow,
        onChange: onSelectChange,
        fixed: true,
    };

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: count }));
    }, [count]);

    useEffect(() => {
        if (catalogError) {
            notification.error((catalogError as any)?.data?.message);
        }
    }, [catalogError]);

    useEffect(() => {
        if (error) {
            notification.error((error as any)?.data?.message);
        }
    }, [error]);

    return (
        <Spin spinning={isLoading || isLoadingRules || isLoadingRestart}>
            <Modal
                onCancel={toggleUntriggeredRisksModal}
                destroyOnClose
                title={`Друк ризики`}
                open={untriggeredRisksModal}>
                <SessionReportModal onSuccess={toggleUntriggeredRisksModal} reportType={ReportTypes.untriggeredRisks} />
            </Modal>
            <Modal
                onCancel={toggleUntriggeredIndicatorsModal}
                destroyOnClose
                title={`Друк індикатори`}
                open={untriggeredIndicatorsModal}>
                <SessionReportModal
                    onSuccess={toggleUntriggeredIndicatorsModal}
                    reportType={ReportTypes.untriggeredIndicators}
                />
            </Modal>
            <Modal
                onCancel={closeSessionRegisterModal}
                destroyOnClose
                title={`Друк реєстру сесій`}
                open={sessionRegisterModal}>
                <ReportModal
                    onSuccess={closeSessionRegisterModal}
                    reportType={ReportTypes.sessionRegister}
                    filters={filter}
                    processedFilters={makeFilterQuery(filter)}
                />
            </Modal>
            <Row gutter={8} justify="end" className="marginBottom2 alignItemsCenter">
                <Col>
                    <Checkbox onChange={handleCheckboxChange} checked={getAllSessions}>
                        Всі сесії
                    </Checkbox>
                </Col>
                <Col>
                    <Button disabled={disabledRestartButton} onClick={submitRestartSession}>
                        Запуск
                    </Button>
                </Col>
                <Col>
                    <Dropdown
                        menu={{
                            items: printButtons(
                                toggleUntriggeredRisksModal,
                                toggleUntriggeredIndicatorsModal,
                                openSessionRegisterModall,
                            ),
                        }}>
                        <Button>
                            <Space>
                                <PrinterOutlined />
                                <CaretDownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                </Col>
                <Col>
                    <Button icon={<ReloadOutlined />} onClick={refetch} loading={isFetching} />
                </Col>
            </Row>
            <Table<Session>
                loading={isLoading || isFetching}
                rowKey="id"
                size="small"
                scroll={{ x: 600 }}
                columns={getColumns(rules, indicators, roles, sizes, getRoles, inputRef, getStatuses, sessionStatuses)}
                rowSelection={rowSelection}
                dataSource={collection}
                pagination={pagination}
                onChange={handleTableChange}
                bordered
            />
        </Spin>
    );
};

export default SessionRegister;
