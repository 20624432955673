import buildQuery, { QueryOptions } from 'odata-query';
import { createApi } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';
import { SINGLE_SESSION_KEY } from '../../constants';
import { RULES_OPTION_PARAMETER_TYPE_DICTIONARY, RULES_OPTION_PARAMETER_TYPE_VOCABULARY } from '../../constants/Rules';
import { RuleHistory, RuleModel, RuleParamModel } from '../../models/RulesModel';
import { Interval, Session } from '../../models/Session';

type GetHistoryResponse = {
    count: number;
    items: RuleHistory[];
};

interface GetSessionPercentResponse {
    create?: GetSessionCompletionPercentResponse;
    rules?: GetSessionCompletionPercentResponse;
}
export interface GetSessionCompletionPercentResponse {
    totalCount: number;
    count: number;
    percentage: number;
    desc: string;
}
interface SessionResponse {
    count: number;
    items: Session[];
}
// todo сортировка пока на бекенде не будет добавлено поле order
function compareParams(a: RuleParamModel, b: RuleParamModel): number {
    if (a.key === 'period' && b.key === 'periodType') {
        return -1;
    } else if (a.key === 'periodType' && b.key === 'period') {
        return 1;
    } else {
        return 0;
    }
}
export const rulesApi = createApi({
    reducerPath: 'rulesApi',
    baseQuery: customBaseQuery,
    tagTypes: ['Rule', 'Rules', 'Session'],
    endpoints: (builder) => ({
        getSessions: builder.query<SessionResponse, Partial<QueryOptions<Session>>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `client/rules/sessions${queryString}`,
                    method: 'GET',
                };
            },
            providesTags: ['Session'],
        }),
        getRules: builder.query<RuleModel[], Partial<QueryOptions<RuleModel>>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `client/rules${queryString}`,
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json; charset=windows-1251' },
                };
            },
            providesTags: ['Rules'],
        }),
        getRule: builder.query<RuleModel, string | number | undefined>({
            query(id) {
                return {
                    url: `client/rules/${id}`,
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json; charset=windows-1251' },
                };
            },
            providesTags: ['Rule'],
            transformResponse: (response: RuleModel) => {
                const catalog: RuleParamModel[] = [];
                const params: RuleParamModel[] = [];
                (response?.params as RuleParamModel[])
                    ?.filter((param) => !param.isSystem)
                    .forEach((param) => {
                        if (
                            param.type?.code === RULES_OPTION_PARAMETER_TYPE_DICTIONARY ||
                            param.type?.code === RULES_OPTION_PARAMETER_TYPE_VOCABULARY
                        ) {
                            catalog.push(param);
                        } else {
                            params.push(param);
                        }
                    });

                response.params = params.sort(compareParams);
                response.catalog = catalog;

                return response;
            },
        }),
        startSession: builder.mutation<void, Session>({
            query(data) {
                const { id } = data;
                return {
                    url: `client/rules/sessions/${id}`,
                    method: 'POST',
                };
            },
            invalidatesTags: ['Session'],
        }),
        createRule: builder.mutation<void, RuleModel>({
            query(data) {
                return {
                    url: `client/rules`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['Rules'],
        }),
        updateRule: builder.mutation<void, RuleModel>({
            query(data) {
                return {
                    url: `client/rules/${data.id}`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: ['Rule', 'Rules'],
        }),
        deleteRule: builder.mutation<void, number>({
            query(id) {
                return {
                    url: `client/rules/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['Rule'],
        }),
        getRuleHistory: builder.query<GetHistoryResponse, Partial<QueryOptions<RuleHistory>>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `client/rules/history${queryString}`,
                    method: 'GET',
                };
            },
            transformResponse: (dictionary: GetHistoryResponse) => {
                dictionary.items.forEach((item) => {
                    if (
                        typeof item.newValue === 'object' &&
                        !Array.isArray(item.newValue.params) &&
                        item.newValue.params?.description === 'Пов’язані особи'
                    ) {
                        (item.newValue.params.value as string[]).sort((a, b) =>
                            a.localeCompare(b, undefined, { numeric: true }),
                        );
                    }
                    if (
                        typeof item.prevValue === 'object' &&
                        !Array.isArray(item.prevValue.params) &&
                        item.prevValue.params?.description === 'Пов’язані особи'
                    ) {
                        (item.prevValue.params.value as string[]).sort((a, b) =>
                            a.localeCompare(b, undefined, { numeric: true }),
                        );
                    }
                });
                return dictionary;
            },
            providesTags: ['Rule'],
        }),
        getSessionCompletionPercent: builder.query<GetSessionPercentResponse, Interval['code']>({
            query(type = SINGLE_SESSION_KEY) {
                return {
                    url: `client/rules/sessions/${type}/completion-percent`,
                    method: 'GET',
                };
            },
            onQueryStarted: async (_arg, { queryFulfilled, dispatch }) => {
                try {
                    await queryFulfilled;
                } catch (e) {
                    // @ts-ignore respoonse have error status field but not in types definition
                    if (e?.error?.status === 404) {
                        dispatch(rulesApi.util.invalidateTags(['Session']));
                    }
                }
            },
        }),
        enterEditingMode: builder.query<void, number>({
            query(ruleId) {
                return {
                    url: `client/rules/${ruleId}/edit`,
                    method: 'GET',
                };
            },
        }),
        exitEditingMode: builder.query<void, number>({
            query(ruleId) {
                return {
                    url: `client/rules/${ruleId}/edit`,
                    method: 'DELETE',
                };
            },
        }),
    }),
});

export const {
    useGetSessionsQuery,
    useGetRulesQuery,
    useGetRuleQuery,
    useStartSessionMutation,
    useUpdateRuleMutation,
    useGetRuleHistoryQuery,
    useLazyGetRulesQuery,
    useLazyGetSessionCompletionPercentQuery,
    useLazyEnterEditingModeQuery,
    useLazyExitEditingModeQuery,
} = rulesApi;
